import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';

const socket = io("https://sfe.ecommcube.com", {
  transports: ["websocket"]
});

const App = () => {
  const [userID, setUserID] = useState('');  // State to store the user ID
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [room, setRoom] = useState('');
  const [privateMessage, setPrivateMessage] = useState('');
  const [targetUserId, setTargetUserId] = useState('');
  const [privateMessages, setPrivateMessages] = useState([]);

  useEffect(() => {
    // Listening for the user ID from the server
    socket.on('yourID', (id) => {
      setUserID(id);  // Set the user ID in state
    });

    // Listening for room messages from the server
    socket.on('message', (msg) => {
      setMessages((prevMessages) => [...prevMessages, msg]);
    });

    // Listening for private messages from the server
    socket.on('privateMessage', ({ from, message }) => {
      setPrivateMessages((prevMessages) => [...prevMessages, `From ${from}: ${message}`]);
    });

    // Cleanup on component unmount
    return () => {
      socket.off('yourID');
      socket.off('message');
      socket.off('privateMessage');
    };
  }, []);

  const joinRoom = () => {
    if (room) {
      socket.emit('joinRoom', room);
    }
  };

  const sendMessageToRoom = () => {
    if (room && message) {
      socket.emit('sendMessageToRoom', { roomName: room, message });
      setMessage('');
    }
  };

  const sendPrivateMessage = () => {
    if (targetUserId && privateMessage) {
      socket.emit('sendPrivateMessage', { toUserId: targetUserId, message: privateMessage });
      setPrivateMessage('');
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <h1>Socket.io Group & Private Messaging</h1>

      {/* Display the user ID */}
      <div>
        <h2>Your User ID: {userID}</h2>
      </div>

      <div>
        <h2>Room Messaging</h2>
        <input
          type="text"
          value={room}
          onChange={(e) => setRoom(e.target.value)}
          placeholder="Enter room name"
          style={{ padding: '10px', marginRight: '10px' }}
        />
        <button onClick={joinRoom} style={{ padding: '10px 20px' }}>
          Join Room
        </button>
        <div style={{ marginTop: '20px' }}>
          {messages.map((msg, index) => (
            <div key={index}>{msg}</div>
          ))}
        </div>
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type a message"
          style={{ padding: '10px', width: '300px', marginRight: '10px', marginTop: '20px' }}
        />
        <button onClick={sendMessageToRoom} style={{ padding: '10px 20px' }}>
          Send to Room
        </button>
      </div>

      <div style={{ marginTop: '40px' }}>
        <h2>Private Messaging</h2>
        <input
          type="text"
          value={targetUserId}
          onChange={(e) => setTargetUserId(e.target.value)}
          placeholder="Enter user ID"
          style={{ padding: '10px', marginRight: '10px' }}
        />
        <input
          type="text"
          value={privateMessage}
          onChange={(e) => setPrivateMessage(e.target.value)}
          placeholder="Type a private message"
          style={{ padding: '10px', width: '300px', marginRight: '10px' }}
        />
        <button onClick={sendPrivateMessage} style={{ padding: '10px 20px' }}>
          Send Private Message
        </button>
        <div style={{ marginTop: '20px' }}>
          {privateMessages.map((msg, index) => (
            <div key={index}>{msg}</div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default App;
